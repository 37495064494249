import { FC, MouseEvent, ReactNode, useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { Xmark } from '@gravity-ui/icons';
import { timeline } from 'motion';
import { StyledBody, StyledContainer, StyledFooter, StyledHeader, StyledLayout, StyledTitle } from './styles';
import { Size } from './types';
import { Button } from '../Button';
import { GlobalColors } from '../GlobalStyle';

interface Props {
  isVisible: boolean
  onClose: () => void
  title?: string | null | ReactNode
  size?: Size
  isCentred?: boolean
  width?: string
  children?: ReactNode
  footer?: ReactNode
}

export const Modal: FC<Props> = ({
  isVisible,
  onClose,
  size,
  width,
  title,
  isCentred = false,
  children,
  footer,
}) => {
  const layoutRef = useRef(null);
  const containerRef = useRef(null);
  const [target, setTarget] = useState<HTMLElement | null>(null);

  useEffect(() => {
    if (layoutRef.current && containerRef.current) {
      timeline([
        [layoutRef.current, { opacity: [0, 1] }, { duration: 0.3 }],
        [containerRef.current, { scale: [1.25, 1] }, { duration: 0.3, at: 0 }],
      ]);
    }

    const handleMouseDown = (e: globalThis.MouseEvent) => {
      setTarget(e.target as HTMLElement);
    };

    const handleKeyDown = (e: globalThis.KeyboardEvent) => {
      if (e.key === 'Escape') {
        onClose();
      }
    };

    if (isVisible) {
      document.addEventListener('mousedown', handleMouseDown);
      document.addEventListener('keydown', handleKeyDown);
    }

    return () => {
      document.removeEventListener('mousedown', handleMouseDown);
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [isVisible, onClose]);

  const handleClose = () => {
    if (target === layoutRef.current) {
      onClose();
    }
  };

  if (!isVisible) return null;

  return createPortal(
    <StyledLayout
      onMouseUp={handleClose}
      ref={layoutRef}
      $centred={isCentred}
      id="modal"
    >
      <StyledContainer
        $size={size ?? 'medium'}
        $width={width ?? '100%'}
        onMouseUp={(e: MouseEvent<HTMLDivElement>) => e.stopPropagation()}
        onClick={(e: MouseEvent<HTMLDivElement>) => e.stopPropagation()}
        ref={containerRef}
        data-testid="modalContainer"
      >
        <StyledHeader>
          {title && <StyledTitle>{title}</StyledTitle>}

          <Button view="ghost" onClick={onClose}>
            <Xmark color={GlobalColors.icon.disable} />
          </Button>
        </StyledHeader>

        <StyledBody>
          {children}
        </StyledBody>
        {footer && (
          <StyledFooter>
            {footer}
          </StyledFooter>
        )}
      </StyledContainer>
    </StyledLayout>,
    document.body,
  );
};
