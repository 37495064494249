import { Menu } from '@base-ui-components/react/menu';
import { ChevronDown, Check, Xmark } from '@gravity-ui/icons';
import { MouseEvent, ReactNode } from 'react';
import { GlobalColors } from '../GlobalStyle';
import {
  PopupStyle,
  TriggerStyle,
  CheckboxItemStyle,
  CountStyle,
  OptionStyle,
  PositionerStyle,
  CheckboxIndicatorStyle,
  ControlStyle,
} from './styles';

export type SelectorOption<T extends string = string> = {
  value: T
  name?: string | JSX.Element
  icon?: string | JSX.Element
  customIcon?: ReactNode
  checked?: boolean
}

type Props <T extends string = string> = {
  title: ReactNode
  options: SelectorOption<T>[]
  width?: number
  header?: ReactNode
  checkedCount: number
  paddingSize?: 's' | 'default'
  onChange(value: SelectorOption, checked: boolean): void
  onReset(): void
}

export const MultiSelect = <T extends string = string>({
  title,
  options,
  header,
  width = 300,
  checkedCount = 0,
  paddingSize = 'default',
  onChange,
  onReset,
}: Props<T>) => {
  const handleReset = (e: MouseEvent<HTMLButtonElement>) => {
    onReset();
    e.stopPropagation();
    e.preventDefault();
  };

  return (
    <Menu.Root>
      <ControlStyle>
        <TriggerStyle>
          {title}
          <ChevronDown color={GlobalColors.icon.ghost} />
        </TriggerStyle>
        {checkedCount > 0 && (
          <CountStyle onClick={(e) => handleReset(e)}>
            {checkedCount}<Xmark width={14} height={14} />
          </CountStyle>
        )}
      </ControlStyle>

      <Menu.Portal>
        <PositionerStyle $width={width} sideOffset={4} side="bottom" align="start" $withHeader={!!header}>
          {header}
          <PopupStyle $withHeader={!!header}>
            {options.map((option) => (
              <CheckboxItemStyle
                $paddingSize={paddingSize}
                key={option.value}
                checked={option.checked}
                onCheckedChange={(checked: boolean) => onChange(option, checked)}
              >
                <OptionStyle>{option.icon} {option.name}</OptionStyle>
                <CheckboxIndicatorStyle>
                  <Check color={GlobalColors.icon.ghost} />
                </CheckboxIndicatorStyle>
              </CheckboxItemStyle>
            ))}
          </PopupStyle>
        </PositionerStyle>
      </Menu.Portal>
    </Menu.Root>
  );
};
