import { css, styled } from 'styled-components';
import { GlobalColors } from '../GlobalStyle';

export const MenuStyle = styled.div`
  //background: rgba(255, 255, 255, 0.8);
  //-webkit-backdrop-filter: blur(8px);
  //backdrop-filter: blur(8px);
  //padding: 4px;
  //border-radius: 6px;
  //box-shadow: 2px 4px 12px rgba(0, 0, 0, 0.1), 0 0 0 1px rgba(0, 0, 0, 0.1);
  //outline: 0;
  padding: 4px;
  flex-direction: column;
  justify-content: center;
  gap: 4px;
  border-radius: 12px;
  background: ${GlobalColors.bg.main.secondary};
  box-shadow: 0 12px 24px 0 rgba(22, 34, 51, 0.06), 0 2px 8px 0 rgba(22, 34, 51, 0.04);
  border: 1px solid rgba(0, 0, 0, 0.05);
  z-index: 100000020;
  outline: none;
`;

// root menu trigger style
const rootItem = css`
  &[data-open] > *,
  &:hover > * {
    background: ${GlobalColors.bg.gray.ghostHover};
  }
`;

// nested item style
const nestedItem = (active: boolean) => css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: none;
  width: 100%;
  border: none;
  text-align: left;
  min-width: 124px;
  margin: 0;
  outline: 0;
  padding: 4px;
  box-sizing: border-box;
  gap: 4px;
  border-radius: 8px;
  min-height: 32px;
  background: ${active ? GlobalColors.bg.darkBlue.ghostHover : 'transparent'};

  &:focus {
    background: ${GlobalColors.bg.darkBlue.disable};
  }

  &[data-nested][data-open]:not([data-focus-inside]) {
    background: ${GlobalColors.bg.darkBlue.disable};
  }

  &[data-focus-inside][data-open] {
    background: ${GlobalColors.bg.darkBlue.disable};
  }
  
  &:disabled {
    color: ${GlobalColors.text.disable};
    pointer-events: none;
  }
`;

export const MenuItemStyle = styled.button<{ $isNested?: boolean, $active?: boolean }>`
  ${({ $isNested = true, $active = false }) => ($isNested ? nestedItem($active) : rootItem)}
`;

export const MenuPostfixStyle = styled.span`
  display: flex;
  align-self: center;
  color: ${GlobalColors.icon.ghost};
`;
