import { Picture } from '../Picture';
import { LogoStyle } from './styles';
import { LogoFallback } from './LogoFallback';

type Props = {
  src?: string
  alt?: string
  size?: number
  objectFit?: 'contain' | 'cover'
}

/**
 * Logo component that displays an image or a fallback text if the image is not provided.
 *
 * @param {string} src - The source URL of the logo image.
 * @param {string} alt - The alternative text for the logo image.
 * @param {number} size - The size of the logo.
 * @param {'contain' | 'cover'} objectFit - The object-fit property for the logo image.
 * @returns {JSX.Element} The Logo component.
 */
export const Logo = ({ src, alt, size, objectFit = 'cover' }: Props) => {
  if (src) {
    return (
      <LogoStyle $size={size}>
        <Picture src={src} alt={alt} objectFit={objectFit} />
      </LogoStyle>
    );
  }

  return <LogoFallback size={size} text={alt} />;
};
