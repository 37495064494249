import styled, { css } from 'styled-components';
import { GlobalColors } from '../GlobalStyle';
import { SizeType, StatusType, ColorType } from './types';

interface CheckboxProps {
  disabled?: boolean
  $status: StatusType
  $size: SizeType
  $color: ColorType
}

interface LabelProps {
  $disabled?: boolean
}

export const Label = styled.label<LabelProps>`
  display: flex;
  align-items: baseline;
  gap: 8px;
  flex-shrink: 1;
  font-weight: 600;
  font-size: 14px;
  color: ${({ $disabled }) => ($disabled
    ? GlobalColors.text.disable
    : GlobalColors.text.primary)};
`;

export const CheckboxStyle = styled.input<CheckboxProps>`
  -webkit-appearance: none;
  appearance: none;
  border-radius: 4px;
  display: grid;
  place-content: center;
  transform: translateY(-0.075em);
  background: ${GlobalColors.bg.main.secondary};
  
  ${({ $size }) => {
    switch ($size) {
      case 'sm':
        return css`
          width: 16px;
          height: 16px;
          &::before { width: 8px; height: 8px; }
        `;
      case 'lxl':
      default:
        return css`
          width: 20px;
          height: 20px;
          &::before { width: 10px; height: 10px; }
        `;
    }
  }
};

  ${({ $status }) => {
    switch ($status) {
      case 'error':
        return css`
          border: 1px solid ${GlobalColors.border.error};
        `;
      default:
        return css`
          border: 1px solid ${GlobalColors.border.primary};
        `;
    }
  }
};

  &:focus-visible {
    outline: 0;
    border: 2px solid ${GlobalColors.border.focus};
  }

  &:hover {
    border: 1px solid ${GlobalColors.border.primaryHover};
  }
  
  &:checked {
    ${({ $color }) => {
    switch ($color) {
      case 'green':
        return css`
          border: 1px solid ${GlobalColors.bg.success.primary};
          background: ${GlobalColors.bg.success.primary};
        `;
      default:
        return css`
            border: 1px solid ${GlobalColors.bg.blue.primary};
            background: ${GlobalColors.bg.blue.primary};
        `;
    }
  }
};}

  &:checked::before {
    transform: scale(1);
  }
  
  &::before {
    content: "";
    box-sizing: border-box;
    clip-path: polygon(14% 44%, 0 44%, 40% 90%, 100% 0, 86% 0%, 43% 62%);
    transform: scale(0);
    transform-origin: bottom left;
    transition: 120ms transform ease-in-out;
    background-color: ${GlobalColors.icon.primaryOnColor};
  }

  &:disabled {
    pointer-events: none;
    border: 1px solid ${GlobalColors.bg.gray.disable};
    background: ${GlobalColors.bg.gray.disable};
  }

  &:disabled::before {
    background-color: ${GlobalColors.icon.disable};
  }
`;
