import styled, { css } from 'styled-components';
import { Menu } from '@base-ui-components/react/menu';
import { GlobalColors } from '../GlobalStyle';

export const CountStyle = styled.button`
  all: unset;
  display: flex;
  box-sizing: border-box;
  min-width: 38px;
  height: 16px;
  padding: 0 6px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 16px;
  font-size: 12px;
  font-weight: 600;
  line-height: 14px;
  font-variant-numeric: tabular-nums;
  background: ${GlobalColors.bg.blue.primary};
  color: ${GlobalColors.text.primaryOnColor};
  cursor: pointer;
  z-index: 16;
    
  &:hover {
    cursor: pointer;
    background: ${GlobalColors.bg.blue.primaryHover};
  }

  &:focus-visible {
    outline: 2px solid ${GlobalColors.border.focus};
  }
`;

export const OptionStyle = styled.span`
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
    
    & > svg {
      flex-shrink: 0;
      margin-left: 4px;
      width: 16px;
      height: 16px;
    }
    & > label {
      flex-shrink: 0;
    }
`;

export const ControlStyle = styled.div`
  display: flex;
  gap: 8px;
  padding-right: 8px;
  background: ${GlobalColors.bg.darkBlue.ghostHover};
  border-radius: 8px;
  align-items: center;
  
  @media (hover: hover) {
    &:hover {
      background-color: ${GlobalColors.bg.darkBlue.ghostActive};
    }
  }
  
  &:has(button:active) {
    background-color: ${GlobalColors.bg.darkBlue.ghostActive};
  }
  
  &:has(*[data-popup-open]) {
    background-color: ${GlobalColors.bg.darkBlue.ghostActive};
  }

  &:has(button:focus-visible) {
    outline: 2px solid ${GlobalColors.border.focus};
    outline-offset: -1px;
  }
`;

export const TriggerStyle = styled(Menu.Trigger)`
  display: flex;
  align-items: center;
  gap: 8px;
  padding-left: 12px;
  height: 32px;
  user-select: none;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  color: ${GlobalColors.text.secondary};
  text-overflow: ellipsis;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  outline: none;
`;

export const PositionerStyle = styled(Menu.Positioner)<{ $withHeader: boolean, $width: number }>`
  z-index: 20;
  width: ${({ $width }) => $width}px;
  ${({ $withHeader }) => $withHeader && css`
    background: #FFF;
    border-radius: 12px;
    box-shadow: 0 2px 8px 0 rgba(22, 34, 51, 0.04), 0 12px 24px 0 rgba(22, 34, 51, 0.06);
    transition: opacity 350ms;

    &[data-starting-style],
    &[data-ending-style] {
        opacity: 0;
    }
  `};
`;

export const PopupStyle = styled(Menu.Popup)<{ $withHeader: boolean }>`
  display: flex;
  padding: 4px;
  flex-direction: column;
  align-items: flex-start;
  gap: 2px;
  background: #FFF;
  border-radius: 12px;
  transition:
    transform 50ms,
    opacity 50ms;

  &[data-starting-style],
  &[data-ending-style] {
    opacity: 0;
    transform: scale(0.9);
  }
 
  ${({ $withHeader }) => !$withHeader && css`
     box-shadow: 0 2px 8px 0 rgba(22, 34, 51, 0.04), 0 12px 24px 0 rgba(22, 34, 51, 0.06);
  `};

  @media (prefers-color-scheme: light) {
    outline: 1px solid #FFF;
    outline-offset: -1px;
  }

  @media (prefers-color-scheme: dark) {
    outline: 1px solid #FFF;
    outline-offset: -1px;
  }
`;

export const CheckboxItemStyle = styled(Menu.CheckboxItem)<{ $paddingSize: 's' | 'default' }>`
  outline: 0;
  cursor: pointer;
  align-items: flex-start;
  display: flex;
  box-sizing: border-box;
  justify-content: space-between;
  min-height: 32px;
  padding: ${({ $paddingSize }) => ($paddingSize === 's' ? '4px 6px 4px 4px' : '8px 6px 8px 4px')};
  align-self: stretch;
  border-radius: 8px;

  &[data-highlighted] {
    background: rgba(13, 45, 94, 0.04);
  }

  &[data-checked] {
      background: rgba(13, 45, 94, 0.04);
  }
`;

export const CheckboxIndicatorStyle = styled(Menu.CheckboxItemIndicator)`
  align-self: center;
`;
