/* eslint-disable */
import * as React from 'react';
import { SVGProps } from 'react';

export const EmptyPerson = (props: SVGProps<SVGSVGElement>) => (
  <svg width="10" height="12" viewBox="0 0 10 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="Group 1">
      <ellipse id="Vector" cx="5.00016" cy="3.33366" rx="2.66667" ry="2.66667" fill="#EC3F39"/>
      <ellipse id="Vector_2" opacity="0.5" cx="5.00016" cy="9.33366" rx="4.66667" ry="2.66667" fill="#EC3F39"/>
    </g>
  </svg>
);