import {
  FC, ReactNode, useState, MouseEvent, useEffect, useRef, KeyboardEvent,
} from 'react';
import { createPortal } from 'react-dom';
import { timeline } from 'motion';
import { Container, Layout, Body, Content } from './styles';
import { Header } from './Header';

export type Align = 'left' | 'right';

interface Props {
  visible: boolean
  close: (e?: React.MouseEvent<HTMLElement>) => void
  children?: ReactNode
  footer?: ReactNode
  title?: string | ReactNode
  collapseToggle?: boolean
  align?: Align
  controls?: ReactNode
  isScrollable?: boolean
  collapsed?: boolean
  withPadding?: boolean
}

export const Drawer: FC<Props> = ({
  visible,
  close,
  children,
  footer,
  title,
  collapseToggle = false,
  align = 'right',
  controls,
  isScrollable = true,
  collapsed = true,
  withPadding = true,
}) => {
  const [isCollapsed, setIsCollapsed] = useState<boolean>(collapsed);
  const [target, setTarget] = useState<HTMLElement | null>(null);

  const layoutRef = useRef(null);
  const containerRef = useRef(null);

  useEffect(() => {
    if (layoutRef.current && containerRef.current) {
      timeline([
        [layoutRef.current, { opacity: 1 }, { duration: 0.2 }],
        // [containerRef.current, { x: [400, 0] }, { duration: 0.3, at: '-0.2' }],
      ]);
    }

    const handleMouseDown = (e: globalThis.MouseEvent) => {
      setTarget(e.target as HTMLElement);
    };

    const handleKeyDown = (e: globalThis.KeyboardEvent) => {
      if (e.key === 'Escape') {
        close();
      }
    };

    if (visible) {
      document.addEventListener('mousedown', handleMouseDown);
      document.addEventListener('keydown', handleKeyDown);
    }

    return () => {
      document.removeEventListener('mousedown', handleMouseDown);
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [close, visible]);

  const handleClose = () => {
    if (target === layoutRef.current) {
      close();
    }
  };

  if (!visible) return null;

  return createPortal(
    <Layout
      $align={align}
      onMouseUp={handleClose}
      ref={layoutRef}
      style={{ opacity: 0 }}
    >
      <Container
        $isCollapsed={isCollapsed}
        onMouseUp={(e: MouseEvent<HTMLDivElement>) => e.stopPropagation()}
        onClick={(e: MouseEvent<HTMLDivElement>) => e.stopPropagation()}
        ref={containerRef}
        data-testid="drawerContainer"
      >
        <Header
          title={title}
          close={close}
          collapseToggle={collapseToggle}
          isCollapsed={isCollapsed}
          toggleCollapse={setIsCollapsed}
          controls={controls}
          isScrollable={isScrollable}
        />
        <Body $isScrollable={isScrollable} $withPadding={withPadding}>
          { isScrollable ? <Content>{children}</Content> : children }
        </Body>
        {footer}
      </Container>
    </Layout>,
    document.body,
  );
};
