export const GlobalColors = {
  text: {
    primary: 'var(--main-gray)',
    secondary: 'var(--main-gray-700)',
    secondaryButton: 'var(--main-dark-blue-700)',
    disable: 'var(--main-dark-blue-400)',
    ghost: 'var(--main-dark-blue-600)',
    ghostHover: 'var(--main-dark-blue-700)',
    ghostActive: 'var(--main-dark-blue)',
    accent: 'var(--main-blue)',
    accentHover: 'var(--main-blue-800)',
    accentActive: 'var(--main-blue-900)',
    error: 'var(--main-red)',
    danger: 'var(--main-orange)',
    success: 'var(--main-green)',
    warning: 'var(--main-yellow-1000)',
    primaryOnColor: 'var(--main-white)',
  },
  icon: {
    primary: 'var(--main-gray)',
    secondary: 'var(--main-dark-blue-700)',
    disable: 'var(--main-dark-blue-400)',
    ghost: 'var(--main-dark-blue-600)',
    ghostHover: 'var(--main-dark-blue-700)',
    ghostActive: 'var(--main-dark-blue)',
    accent: 'var(--main-blue)',
    accentHover: 'var(--main-blue-800)',
    accentActive: 'var(--main-blue-900)',
    error: 'var(--main-red)',
    danger: 'var(--main-orange)',
    success: 'var(--main-green)',
    warning: 'var(--main-yellow-1000)',
    primaryOnColor: 'var(--main-white)',
  },
  border: {
    primary: 'var(--main-dark-blue-300)',
    primaryHover: 'var(--main-dark-blue-600)',
    secondary: 'var(--main-dark-blue-200)',
    disable: 'var(--main-dark-blue-100)',
    active: 'var(--main-blue)',
    activeSecondary: 'var(--main-blue-300)',
    error: 'var(--main-red-600)',
    errorSecondary: 'var(--main-red-300)',
    danger: 'var(--main-orange-600)',
    dangerSecondary: 'var(--main-orange-300)',
    success: 'var(--main-green-600)',
    successSecondary: 'var(--main-green-300)',
    warning: 'var(--main-yellow-600)',
    warningSecondary: 'var(--main-yellow-300)',
    focus: 'var(--main-blue-600)',
    focusError: 'var(--main-red-600)',
    focusDanger: 'var(--main-orange-600)',
    focusWarning: 'var(--main-yellow-600)',
    focusSuccess: 'var(--main-green-600)',
  },
  bg: {
    main: {
      primary: 'var(--main-dark-blue-50)',
      secondary: 'var(--main-white)',
      thirdly: 'var(--main-dark-blue-100)',
    },
    gray: {
      primary: 'var(--main-gray)',
      primaryHover: 'var(--main-gray-800)',
      primaryActive: 'var(--main-gray-900)',
      secondary: 'var(--main-gray-300)',
      secondaryHover: 'var(--main-gray-400)',
      secondaryActive: 'var(--main-gray-500)',
      outlineHover: 'var(--main-gray-200)',
      outlineActive: 'var(--main-gray-300)',
      ghostHover: 'var(--main-gray-200)',
      ghostActive: 'var(--main-gray-300)',
      disable: 'var(--main-gray-100)',
    },
    darkBlue: {
      secondary: 'var(--main-dark-blue-200)',
      secondaryHover: 'var(--main-dark-blue-300)',
      secondaryActive: 'var(--main-dark-blue-400)',
      outlineHover: 'var(--main-dark-blue-200)',
      outlineActive: 'var(--main-dark-blue-300)',
      ghostHover: 'var(--main-dark-blue-100)',
      ghostActive: 'var(--main-dark-blue-200)',
      disable: 'var(--main-dark-blue-100)',
    },
    blue: {
      primary: 'var(--main-blue)',
      primaryHover: 'var(--main-blue-800)',
      primaryActive: 'var(--main-blue-900)',
      secondary: 'var(--main-blue-200)',
      secondaryHover: 'var(--main-blue-300)',
      secondaryActive: 'var(--main-blue-400)',
      outlineHover: 'var(--main-blue-200)',
      outlineActive: 'var(--main-blue-300)',
      ghostHover: 'var(--main-blue-200)',
      ghostActive: 'var(--main-blue-300)',
    },
    error: {
      primary: 'var(--main-red)',
      primaryHover: 'var(--main-red-800)',
      primaryActive: 'var(--main-red-900)',
      secondary: 'var(--main-red-200)',
      secondaryHover: 'var(--main-red-300)',
      secondaryActive: 'var(--main-red-400)',
      outlineHover: 'var(--main-red-200)',
      outlineActive: 'var(--main-red-300)',
      ghostHover: 'var(--main-red-200)',
      ghostActive: 'var(--main-red-300)',
    },
    danger: {
      primary: 'var(--main-orange)',
      primaryHover: 'var(--main-orange-800)',
      primaryActive: 'var(--main-orange-900)',
      secondary: 'var(--main-orange-300)',
      secondaryHover: 'var(--main-orange-400)',
      secondaryActive: 'var(--main-orange-500)',
      outlineHover: 'var(--main-orange-200)',
      outlineActive: 'var(--main-orange-300)',
      ghostHover: 'var(--main-orange-200)',
      ghostActive: 'var(--main-orange-300)',
    },
    success: {
      primary: 'var(--main-green)',
      primaryHover: 'var(--main-green-800)',
      primaryActive: 'var(--main-green-900)',
      secondary: 'var(--main-green-200)',
      secondaryHover: 'var(--main-green-300)',
      secondaryActive: 'var(--main-green-400)',
      outlineHover: 'var(--main-green-200)',
      outlineActive: 'var(--main-green-300)',
      ghostHover: 'var(--main-green-200)',
      ghostActive: 'var(--main-green-300)',
    },
    warning: {
      primary: 'var(--main-yellow)',
      primaryHover: 'var(--main-yellow-800)',
      primaryActive: 'var(--main-yellow-900)',
      secondary: 'var(--main-yellow-400)',
      secondaryHover: 'var(--main-yellow-500)',
      secondaryActive: 'var(--main-yellow-600)',
      outlineHover: 'var(--main-yellow-300)',
      outlineActive: 'var(--main-yellow-400)',
      ghostHover: 'var(--main-yellow-300)',
      ghostActive: 'var(--main-yellow-400)',
    },
  },
};
