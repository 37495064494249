import { FC, InputHTMLAttributes, ReactNode, useMemo, useState } from 'react';
import { TrashBin, Trolley } from '@gravity-ui/icons';
import {
  Wrapper, FileStyle, TextStyle, HoverWrapper, HoverIcon, CountStyle, PictureBlock,
} from './styles';
import { GlobalColors } from '../GlobalStyle';
import { getIconSize } from './helpers';
import { Picture } from '../Picture';
import { ImageUpload } from '../Uploader';

export type ImgSizeType = 16 | 24 | 28 | 32 | 36 | 64 | 106 | 120;
export type ColorStyleType = 'default' | 'success' | 'danger';

type Props = {
  size: 64 | 106 | 120
  isEditable?: true
} | {
  size: 16 | 24 | 28 | 32 | 36
  isEditable?: false
}

export type LogoProps = InputHTMLAttributes<HTMLInputElement> & Props

export interface LogoPicProps extends Omit<InputHTMLAttributes<HTMLInputElement>, 'size' | 'onChange'> {
  size?: ImgSizeType
  src?: string
  alt?: string
  /**  name two first  letters  */
  name?: string
  /**  for no src pic type  */
  customIcon?: ReactNode
  /** catalog updates count / for pic type only / for sizes 24 | 28 | 32 | 36 */
  badgeCount?: number
  colorStyle?: ColorStyleType
  onChange?: (file: File) => void
  /**  reset pic function  */
  onClear?: () => void
  objectFit?: 'contain' | 'cover',
  isEditable?: boolean
}

export const LogoPic: FC<LogoPicProps> = ({
  src,
  onChange,
  onClear,
  size = 64,
  alt = '',
  name,
  customIcon,
  colorStyle = 'default',
  badgeCount,
  objectFit,
  isEditable = true,
}) => {
  const [srcBlob, setSrcBlob] = useState('');

  const handleFileChange = async (files: File[] | null) => {
    const file = files && files[0];
    if (!file) {
      return;
    }
    onChange && file && onChange(file);
    setSrcBlob(URL.createObjectURL(file));
  };

  const handleFileDelete = () => {
    if (src && onClear && (size === 64 || size === 106 || size === 120)) {
      onClear();
      setSrcBlob('');
    }
    return null;
  };

  const isBigSize = useMemo(() => (size === 64 || size === 106 || size === 120), [size]);
  const picSrc = useMemo(() => src || srcBlob, [src, srcBlob]);

  const shortText = useMemo(() => {
    if (typeof name === 'string') {
      return name.split(' ').reduce((acc, items, index) => {
        if (index < 2 && typeof items[0] === 'string') acc += items[0].toUpperCase();
        return acc;
      }, '');
    }
    return '';
  }, [name]);

  if (isEditable && (size === 64 || size === 106 || size === 120) && (!src && !srcBlob)) {
    return (
      <ImageUpload
        size={size}
        uploadFile={handleFileChange}
      />
    );
  }

  return (
    <Wrapper $size={size} tabIndex={0} onClick={handleFileDelete}>
      {picSrc
        ? (
          <PictureBlock $size={size}>
            <Picture src={picSrc} alt={alt} objectFit={objectFit} />
            {/* delete picture icon */}
            {isEditable && isBigSize && (
              <HoverWrapper>
                <HoverIcon $bgColor="error" $size={size}>
                  <TrashBin width={14} height={14} color={GlobalColors.icon.primaryOnColor} />
                </HoverIcon>
              </HoverWrapper>
            )}
          </PictureBlock>
        ) : (
          <>
            <FileStyle $colorStyle={colorStyle} $size={size}>
              <TextStyle $size={size}>
                {/* eslint-disable-next-line max-len */}
                {shortText ?? customIcon ?? <Trolley width={getIconSize(size)} height={getIconSize(size)} color={GlobalColors.icon.primaryOnColor} />}
              </TextStyle>
            </FileStyle>

            {/* updates count */}
            {!isBigSize && !(size === 16) && badgeCount
                && <CountStyle $size={size}>{badgeCount}</CountStyle>}
          </>
        )}
    </Wrapper>
  );
};
