import styled from 'styled-components';
import { GlobalColors } from '../GlobalStyle';

export const LogoStyle = styled.span<{ $size?: number }>`
  font-family: 'PT Root UI VF', Arial, sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${(props) => props.$size ?? 24}px;
  height: ${(props) => props.$size ?? 24}px;
  overflow: hidden;
  border-radius: calc(${(props) => props.$size ?? 24}px/4);
  background: rgba(0, 127, 255, 0.16);
  color: ${GlobalColors.text.accent};
  font-size: ${(props) => ((props.$size ?? 24) > 24 ? 16 : 12)}px;
  font-weight: 500;
`;
