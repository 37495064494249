import { FC, useCallback, useEffect, useState } from 'react';
import { Content, EditorContent, useEditor } from '@tiptap/react';
import { useTranslation } from 'next-i18next';
import StarterKit from '@tiptap/starter-kit';
import Underline from '@tiptap/extension-underline';
import Link from '@tiptap/extension-link';
import Placeholder from '@tiptap/extension-placeholder';
import TextAlign from '@tiptap/extension-text-align';
import Typography from '@tiptap/extension-typography';
import Image from '@tiptap/extension-image';
import Superscript from '@tiptap/extension-superscript';
import TextStyle from '@tiptap/extension-text-style';
import { Color } from '@tiptap/extension-color';
import { Highlight } from '@tiptap/extension-highlight';
import { Youtube } from '@tiptap/extension-youtube';
import { CharacterCount } from '@tiptap/extension-character-count';
import {
  Bold,
  Italic,
  Strikethrough,
  Underline as UnderlineIcon,
  ListOl,
  ListUl,
  Heading1,
  Heading2,
  Heading3,
  Link as LinkIcon,
  LinkSlash,
  PencilToLine,
  TextAlignRight,
  TextAlignLeft,
  TextAlignCenter,
  TextAlignJustify,
  Paperclip,
  Picture,
} from '@gravity-ui/icons';
import { Dropzone } from '../Dropzone';
import IconSuperScript from './IconSuperScript';
import {
  Container,
  StyleIcon,
  StyleButton,
  StyledContent,
  ControlPanel, ReachCounterStyle,
} from '../styles';

interface Props {
  content?: Content | undefined,
  updateContent: (cont: string) => void,
  simple?: boolean,
  counter?: boolean,
  counterLimit?: number,
  onBlur?: (text: string) => void,
}

export const ReachEditor: FC<Props> = ({
  content,
  updateContent,
  onBlur,
  counterLimit,
  simple = false,
  counter = false,
}) => {
  const { t: translation } = useTranslation();
  const [visible, isVisible] = useState(false);
  const [text, setText] = useState(content as string);

  const handleBlur = () => {
    onBlur && onBlur(text);
  };

  const update = (editorText: string) => {
    setText(editorText);
    updateContent(editorText);
  };

  const editor = useEditor({
    extensions: [
      StarterKit.configure({
        heading: {
          levels: [1, 2, 3, 4, 5, 6],
        },
      }),
      Underline,
      Typography,
      Superscript,
      TextStyle,
      Highlight.configure({
        multicolor: true,
      }),
      Placeholder.configure({
        placeholder: String(translation('placeholder.description')),
      }),
      // TODO: refactor this awful mess
      ...(!simple
        ? [
          Image,
          Link,
          Color.configure({
            types: ['textStyle'],
          }),
          TextAlign.configure({
            types: ['heading', 'paragraph'],
          }),
          Youtube.configure({
            width: 480,
            height: 320,
          }),
        ]
        : []
      ),
      ...(counter
        ? [CharacterCount.configure({
          mode: 'textSize',
        })]
        : []
      ),
    ],
    content: text,
    onUpdate: ({ editor }) => (update(editor.getHTML().toString())),
    onBlur: ({ editor }) => (update(editor.getHTML().toString())),
  });

  const toggleLink = useCallback(() => {
    if (editor?.isActive('link')) {
      editor.chain().focus().unsetLink().run();
    } else {
      const previousUrl = editor?.getAttributes('link').href;
      const url = window.prompt('URL', previousUrl);
      if (url === null) {
        return;
      }
      if (url === '') {
        editor?.chain().focus().extendMarkRange('link').unsetLink()
          .run();
        return;
      }
      editor?.chain().focus().extendMarkRange('link').setLink({ href: url })
        .run();
    }
  }, [editor]);

  const addImage = () => {
    const url = window.prompt('URL');

    if (url) {
      editor?.chain().focus().setImage({ src: url }).run();
    }
  };

  if (!editor) {
    return null;
  }

  return (
    <Container>
      <StyledContent>
        <EditorContent
          editor={editor}
          onBlur={handleBlur}
        />
        {visible
          && <Dropzone />}
      </StyledContent>
      {counter && (
        <ReachCounterStyle>
          <span style={{ color: counterLimit && text && text.length > counterLimit ? 'tomato' : '#b3b3b3' }}>
            {text ? text.length : 0}
          </span>
          {counterLimit && <span>/{counterLimit}</span> }
        </ReachCounterStyle>
      )}
      <ControlPanel>
        <StyleButton
          type="button"
          onClick={() => editor.chain().focus().toggleBold().run()}
          $active={editor.isActive('bold')}
        >
          <Bold />
        </StyleButton>
        <StyleButton
          type="button"
          onClick={() => editor.chain().focus().toggleItalic().run()}
          $active={editor.isActive('italic')}
        >
          <StyleIcon>
            <Italic />
          </StyleIcon>
        </StyleButton>
        <StyleButton
          type="button"
          onClick={() => editor.chain().focus().toggleStrike().run()}
          $active={editor.isActive('strike')}
        >
          <StyleIcon>
            <Strikethrough />
          </StyleIcon>
        </StyleButton>
        <StyleButton
          type="button"
          onClick={() => editor.chain().focus().toggleUnderline().run()}
          $active={editor.isActive('underline')}
        >
          <StyleIcon>
            <UnderlineIcon />
          </StyleIcon>
        </StyleButton>
        <StyleButton
          type="button"
          onClick={() => editor.chain().focus().toggleSuperscript().run()}
          $active={editor.isActive('superscript')}
        >
          <StyleIcon>
            <IconSuperScript />
          </StyleIcon>
        </StyleButton>
        <StyleButton
          type="button"
          onClick={() => editor.chain().focus().toggleHighlight().run()}
          className={editor.isActive('highlight') ? 'is-active' : ''}
          $active={editor.isActive('highlight')}
        >
          <PencilToLine />
        </StyleButton>
        <StyleButton
          type="button"
          onClick={() => editor.chain().focus().toggleBulletList().run()}
          $active={editor.isActive('bulletList')}
        >
          <ListUl />
        </StyleButton>
        <StyleButton
          type="button"
          onClick={() => editor.chain().focus().toggleOrderedList().run()}
          $active={editor.isActive('orderedList')}
        >
          <ListOl />
        </StyleButton>
        {!simple && (
          <>
            {/* <StyleButton */}
            {/*  type="button" */}
            {/*  onClick={toggleLink} */}
            {/*  $active={editor.isActive('link')} */}
            {/* > */}
            {/*  {editor?.isActive('link') */}
            {/*    ? <LinkSlash /> */}
            {/*    : <LinkIcon />} */}
            {/* </StyleButton> */}
            <StyleButton
              type="button"
              onClick={() => editor.chain().focus().toggleHeading({ level: 1 }).run()}
              $active={editor.isActive('heading', { level: 1 })}
            >
              <Heading1 />
            </StyleButton>
            <StyleButton
              type="button"
              onClick={() => editor.chain().focus().toggleHeading({ level: 2 }).run()}
              $active={editor.isActive('heading', { level: 2 })}
            >
              <Heading2 />
            </StyleButton>
            <StyleButton
              type="button"
              onClick={() => editor.chain().focus().toggleHeading({ level: 3 }).run()}
              $active={editor.isActive('heading', { level: 3 })}
            >
              <Heading3 />
            </StyleButton>
            <StyleButton
              type="button"
              onClick={() => editor.chain().focus().setTextAlign('left').run()}
              $active={editor.isActive({ textAlign: 'left' })}
            >
              <TextAlignLeft />
            </StyleButton>
            <StyleButton
              type="button"
              onClick={() => editor.chain().focus().setTextAlign('right').run()}
              $active={editor.isActive({ textAlign: 'right' })}
            >
              <TextAlignRight />
            </StyleButton>
            <StyleButton
              type="button"
              onClick={() => editor.chain().focus().setTextAlign('justify').run()}
              $active={editor.isActive({ textAlign: 'justify' })}
            >
              <TextAlignJustify />
            </StyleButton>
            <StyleButton
              type="button"
              onClick={() => editor.chain().focus().setTextAlign('center').run()}
              $active={editor.isActive({ textAlign: 'center' })}
            >
              <TextAlignCenter />
            </StyleButton>
            <StyleButton type="button" onClick={addImage}>
              <Picture />
            </StyleButton>
          </>
        )}
      </ControlPanel>
    </Container>
  );
};
function useCurrentEditor(): { editor: any; } {
  throw new Error('Function not implemented.');
}
