/* eslint-disable @typescript-eslint/no-empty-function */
import { createContext } from 'react';

type PopupContextType = {
  isOpen: boolean;
}

export const PopupContext = createContext<PopupContextType>({
  isOpen: false,
});
