/* eslint-disable consistent-return, no-nested-ternary, react/jsx-no-constructed-context-values */
import {
  useFloatingTree,
  useListItem,
  useMergeRefs,
} from '@floating-ui/react';
import { forwardRef, useContext } from 'react';
import type { ButtonHTMLAttributes, MouseEvent, FocusEvent, ReactNode } from 'react';
import { MenuContext } from './MenuContext';
import { MenuItemStyle } from './styles';

interface MenuItemProps {
  label: string
  disabled?: boolean
  children?: ReactNode
  closeOnClick?: boolean
  active?: boolean
}

export const DropdownMenuItem = forwardRef<
  HTMLButtonElement,
  MenuItemProps & ButtonHTMLAttributes<HTMLButtonElement>
>(({ label, children, disabled, closeOnClick = true, active = false, ...props }, forwardedRef) => {
  const menu = useContext(MenuContext);
  const item = useListItem({ label: disabled ? null : label });
  const tree = useFloatingTree();
  const isActive = item.index === menu.activeIndex;

  return (
    <MenuItemStyle
      {...props}
      $active={active}
      ref={useMergeRefs([item.ref, forwardedRef])}
      type="button"
      role="menuitem"
      tabIndex={isActive ? 0 : -1}
      disabled={disabled}
      {...menu.getItemProps({
        onClick(event: MouseEvent<HTMLButtonElement>) {
          props.onClick?.(event);
          // emit event only if the item is a "button"
          if (closeOnClick) tree?.events.emit('click');
        },
        onFocus(event: FocusEvent<HTMLButtonElement>) {
          props.onFocus?.(event);
          menu.setHasFocusInside(true);
        },
      })}
    >
      {children ?? label}
    </MenuItemStyle>
  );
});
