/* eslint-disable consistent-return, no-nested-ternary, react/jsx-no-constructed-context-values */
import { FloatingTree, Placement, useFloatingParentNodeId } from '@floating-ui/react';
import { forwardRef } from 'react';
import type { HTMLProps, ReactNode } from 'react';
import { Menu } from './Menu';

interface Props {
  label: string
  nested?: boolean
  children?: ReactNode
  control?: ReactNode
  placement?: Placement
}

export const DropdownMenu = forwardRef<
  HTMLButtonElement,
  Props & HTMLProps<HTMLButtonElement>
>((props, ref) => {
  const parentId = useFloatingParentNodeId();

  if (parentId === null) {
    return (
      <FloatingTree>
        <Menu {...props} ref={ref} />
      </FloatingTree>
    );
  }

  return <Menu {...props} ref={ref} />;
});
