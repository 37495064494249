import { LogoStyle } from './styles';
import { useText } from './hooks';

type Props = {
  text?: string
  size?: number
}

export const LogoFallback = ({ text, size }: Props) => {
  const fallback = useText(text);

  return (
    <LogoStyle $size={size}>
      {fallback}
    </LogoStyle>
  );
};
