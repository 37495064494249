/* eslint-disable @typescript-eslint/no-empty-function */
import { createContext } from 'react';
import type { SetStateAction, HTMLProps, Dispatch } from 'react';

type MenuContextType = {
  getItemProps:(
    userProps?: HTMLProps<HTMLElement>
  ) => Record<string, unknown>;
  activeIndex: number | null;
  setActiveIndex: Dispatch<SetStateAction<number | null>>;
  setHasFocusInside: Dispatch<SetStateAction<boolean>>;
  isOpen: boolean;
}

export const MenuContext = createContext<MenuContextType>({
  getItemProps: () => ({}),
  activeIndex: null,
  setActiveIndex: () => {},
  setHasFocusInside: () => {},
  isOpen: false,
});
